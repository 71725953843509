import zh from '@/locales/zh.json'
import en from '@/locales/en.json'
import pageClass from '@/unit/pageClass'

const Lang = {
  zh,
  en,
}
class searchFrom extends pageClass {
  constructor(type, lanuage = 0) {
    super()
    if (type === 'form') {
      this.categoryId = ''
    } else if (type === 'rule') {
      this.receiverId = [
        {
          required: true,
          message: Lang[lanuage === 1 ? `en` : `zh`].ple_ent_receiverId,
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom

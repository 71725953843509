<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
        <el-col :span="20">
          <el-form ref="form" :model="form" label-width="80px">
            <el-row>
              <el-col :span="6">
                <el-form-item label-width="0px">
                  <el-input clearable v-model="form.deviceId" placeholder="设备ID/设备名称"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
        <el-table-column
					type="index"
          align="center"
					label="序号"
					width="55">
				</el-table-column>
				<el-table-column
					prop="deviceName"
          align="center"
					label="设备名称"
					width="180">
				</el-table-column>
				<el-table-column
					prop="deviceId"
          align="center"
					label="设备ID"
					width="180">
				</el-table-column>
				<el-table-column
          align="center"
					prop="content"
					label="实时内容">
				</el-table-column>
        <el-table-column
          align="center"
					prop="batteryInfo"
					label="运行情况">
				</el-table-column>
        <el-table-column
          align="center"
					prop="checkTime"
					label="上报时间">
				</el-table-column>
        <el-table-column
          align="center"
          width="150px"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button class="default-button text-4-button" @click="changeChoseDialog(true, scope.row)">查看历史</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-container">
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
    <!-- 查看历史弹窗 -->
    <chose-dialog ref="choseDialog" :show="choseDialog.visible" @closeDialog="changeChoseDialog"></chose-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import apis from '@/apis'
import mixin from '@/mixins/index'
import confirmDialog from '@/components/confirmDialog.vue'
import dictMixin from '@/mixins/dictMixin'
import choseDialog from './dialog/choseDialog.vue'
import MaterialInfoSearchClass from './materialInfoSearchClass'

export default {
  components: {
    choseDialog,
    confirmDialog,
  },
  mixins: [mixin, dictMixin],
  data() {
    return {
      form: new MaterialInfoSearchClass('form'), // 实例化一个表单的变量
      tableData: [],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
      choseDialog: {
        visible: false,
        data: {},
      },
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.post(apis.deviceMonitorList, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new MaterialInfoSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
        this.$nextTick(() => {
          this.$refs['addDialog'].getDataQuery(data)
        })
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.MaterialInfo_DeleteListAsync, this.confirmDialog.data).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      this.confirmDialog.data.id = id
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} id 选中的ID数组
     */
    openConfirmDialog(id) {
      this.confirmDialog.data.id = id
      this.changeConfirmDialog(true, 2)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data = []
        this.searchEvent()
      }
    },
    /**
     * @function 点击左边的树节点的时候，获取点击的节点数据
     * @param {Object} data 节点信息
     */
    clickNode(data) {
      this.form.categoryId = data.id
      this.searchEvent()
    },
    /**
     * @function 改变选择弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeChoseDialog(dialogStatus, data) {
      this.choseDialog.visible = dialogStatus
      this.$refs.choseDialog.setData(data)
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>

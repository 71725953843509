<template>
  <div class="dialog-container">
    <el-dialog
      title="历史记录"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="60%">
      <!-- 搜素 -->
      <!-- <div class="search-container">
        <el-row>
          <el-col :span="20">
            <el-form ref="form" :model="form" label-width="80px">
              <el-row>
                <el-col :span="6">
                  <el-form-item label-width="0px" prop="name">
                    <el-input clearable v-model="form.name" placeholder="设备名称/设备ID"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <el-col :span="4">
            <el-button @click="searchEvent">搜索</el-button>
          </el-col>
        </el-row>
      </div> -->
      <!-- 表格 -->
      <div class="table-container">
        <el-table
          :data="tableData"
          style="width: 100%"
          height="500px"
          @selection-change="handleSelectionChange">
          <el-table-column
            prop="deviceName"
            align="center"
            label="设备名称">
          </el-table-column>
          <el-table-column
            prop="deviceId"
            align="center"
            label="设备ID">
          </el-table-column>
          <el-table-column
            prop="type"
            align="center"
            label="类型">
            <template slot-scope="scope">
              {{
                DROPDOWNBOX.DEVICE_TYPE.filter(item => item.value === scope.row.type)[0].label
              }}
              <!-- {{scope.row.type === '3' ? '电表传感器' : '烟雾传感器'}} -->
            </template>
          </el-table-column>
          <el-table-column
            prop="content"
            align="center"
            label="实时内容">
          </el-table-column>
          <el-table-column
            prop="batteryInfo"
            align="center"
            label="运行情况">
          </el-table-column>
          <el-table-column
            prop="checkTime"
            align="center"
            label="上报时间">
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total">
        </el-pagination>
      </div>
      <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'
import ChoseDialogClass from './choseDialogClass'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      form: new ChoseDialogClass('form'), // 实例化一个表单的变量
			searchWidthOpen: true, // 是否一打开就自动搜索
      tableData: [], // 表格数据
      inputDialog: {
        visible: false,
      },
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      this.form = new ChoseDialogClass('form')
      this.tableData = []
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求,比如判断选中几条啊，调用接口什么的
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
      this.closeDialog()
      // this.closeDialog()
		},
		/**
		 * @function 搜索功能,分页在这边不考虑，只写获取数据的业务
		 */
		searchData() {
			console.log(`在这里执行搜索操作，请求第${this.form.pageNum}页，每页${this.form.pageSize}条`)
      this.$http.post(this.form.type === '3' ? apis.deviceMonitorList2 : apis.deviceMonitorList1, this.form).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows.list
          this.form.total = res.data.rows.total
        }
      })
    },
    /**
     * @function 打开弹窗触发的事件，原本写在mixin，因为要获取树状数据，在外部覆盖
     */
    openDialog() {
      if (this.searchWidthOpen) {
        this.searchEvent()
      }
    },
    /**
     * @function 点击树状节点触发事件
     * @param {Object} node 点击的树状节点的数据
     */
    clickNode(node) {
      this.form.categoryId = node.id
      this.searchEvent()
    },
    /**
     * @function 改变选择弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     */
    changeInputDialog(dialogStatus) {
      this.inputDialog.visible = dialogStatus
    },
    /**
     * @function 设置数量
     * @param {Number} num 物资数量
     */
    setQty(num) {
      const arr = this.selectedArr.map((item) => {
        const Obj = {
          ...item,
          ...{
            qty: num,
          },
        }
        return Obj
      })
      this.$emit('sureDialog', arr)
      this.closeDialog()
    },
    /**
     * @function 设置值给form
     * @param {Object} data 表格里的值
     */
    setData(data) {
      this.form = {
        ...this.form,
        ...data,
      }
    },
  },
}
</script>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.page-container{
  display: grid;
  justify-content: end;
  margin-top: 20px;
}
.search-container{
  margin-top: 0;
  margin-bottom: 0;
}
</style>
